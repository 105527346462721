<template>
  <div class="mr-header-box">
    <v-header @changeProject="changeProject"></v-header>
    <v-card2 :propsData="projectCode"></v-card2>
  </div>
</template>

<script>
import VHeader from "../components/v-header.vue";
import VCard2 from "../components/v-card2.vue";

export default {
  name: "MrHeaderBox",
  components: {
    VHeader,
    VCard2,
  },
  data() {
    return {
      projectCode: ""
    }
  },
  methods: {
    changeProject(e) {
      this.projectCode = e.projectCode;
      this.$emit("changeProject", e)
    }
  }
}
</script>

<style scoped lang="scss">
.mr-header-box {
  height: 64px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
}
</style>