<template>
  <div class="v-footer">
    <div class="vf-tabs">
      <div
        :class="['vf-tab', active == 'visual' ? 'active' : '']"
        @click="clickTab('visual')"
      >
        综合态势
      </div>
      <div
        :class="['vf-tab', active == 'device' ? 'active' : '']"
        @click="clickTab('device')"
      >
        设备管理
      </div>
      <div
        :class="['vf-tab', active == 'energy' ? 'active' : '']"
        @click="clickTab('energy')"
      >
        能耗管理
      </div>
      <div
        :class="['vf-tab', active == 'break' ? 'active' : '']"
        @click="clickTab('break')"
      >
        故障运维
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VFooter",
  data() {
    return {
      active: 0,
      system: "综合态势",
      systemId: -1,
    };
  },
  computed: {
    getRoute() {
      return this.$route;
    },
  },
  mounted() {
    this.active = this.getRoute.name;
  },
  methods: {
    clickTab(index) {
      this.active = index;
      if (this.getRoute.name == index) return;
      this.goPage(index);
    },
    goPage(index) {
      switch (index) {
        case "visual":
          this.$router.push({
            path: "/visual",
          });
          break;
        case "device":
          this.$router.push({
            path: "/device",
          });
          break;
        case "energy":
          this.$router.push({
            path: "/energy",
          });
          break;
        case "break":
          this.$router.push({
            path: "/break",
          });
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-footer {
  height: 78px;
  .vf-tabs {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    .vf-tab {
      font-family: "microsoft yahei ui","microsoft yahei";
      font-size: 14px;
      font-weight: normal;
      line-height: 36px;
      text-align: right;
      letter-spacing: 0px;
      color: rgba(255, 255, 255, 0.7);
      margin: 0 32px;
      cursor: pointer;
      &.active {
        color: #fff;
        font-size: 16px;
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          width: 100%;
          height: 16px;
          background: url("~@/assets/images/visual/line.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          left: 0;
          bottom: -4px;
        }
      }
    }
  }
}

.vf-content .vf-content-item {
  line-height: 32px;
  cursor: pointer;
  &.active {
    color: #000;
  }
}
</style>
