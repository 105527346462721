<template>
  <div class="v-page-date">
    <template v-if="showBtn">
      <el-button
        v-for="i in opt"
        :key="i.value"
        :type="optVal == i.value ? 'primary' : ''"
        @click="changeOpt(i)"
        >{{ i.label }}</el-button>
    </template>
    <div class="picker-box">
      <el-date-picker
        v-model="dateVal"
        type="date"
        placeholder="请选择日期"
        @change="changeDate"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        :clearable="false"
        :disabled="disabled"
        :append-to-body="false"
      >
      </el-date-picker>
    </div>
    <span class="download" v-if="download">下载</span>
  </div>
</template>


<script>
import { DateToStr } from "@/utils/util/util";
export default {
  naem: "VPageDate",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    showBtn: {
      type: Boolean,
      default: true
    },
    download: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      optVal: 10,
      dateVal: "",
      opt: [
        {
          label: "日",
          value: 10,
        },
        {
          label: "周",
          value: 20,
        },
        {
          label: "月",
          value: 30,
        },
        {
          label: "年",
          value: 40,
        },
      ],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        }
      }
    }
  },
  mounted() {
    this.dateVal = DateToStr(new Date()).split(" ")[0];
    this.changeOpt(this.opt[0])
  },
  methods: {
    changeOpt(i) {
      this.optVal = i.value;
      this.$emit("changeData", { type: this.optVal, dateVal: this.dateVal })
    },
    changeDate() {
      this.$emit("changeData", { type: this.optVal, dateVal: this.dateVal })
    }
  }
}
</script>

<style scoped lang="scss">
.v-page-date {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .picker-box {
    margin-left: 12px;
    margin-right: 12px;
  }

  ::v-deep .el-date-editor {
    border: 1px solid rgba(255, 255, 255, 0.32);
    background: transparent;
    border-radius: 4px;
    input{
      background: transparent;
      color: rgba(255, 255, 255, 0.7);
    }
    .el-date-table td.current:not(.disabled) span {
      background-color: #08D680;
    }
  }

  ::v-deep .el-button {
    border: none; // 1px solid rgba(255, 255, 255, 0.32);
    background: transparent;
    span {
      font-weight: bold;
      color: rgba(255, 255, 255, 0.32);
    }
    &.el-button--primary {
      span {
        color: #08D680;
      }
    }
  }

  .download {
    color: #08D680;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>