import { render, staticRenderFns } from "./v-page-date.vue?vue&type=template&id=0a27c0d3&scoped=true"
import script from "./v-page-date.vue?vue&type=script&lang=js"
export * from "./v-page-date.vue?vue&type=script&lang=js"
import style0 from "./v-page-date.vue?vue&type=style&index=0&id=0a27c0d3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a27c0d3",
  null
  
)

export default component.exports