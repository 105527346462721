<template>
  <div class="v-header">
    <span><v-title-bg>More Inno System</v-title-bg></span>
    
    <div class="vh-list">
      <el-select
        size="mini"
        v-model="project"
        @change="changeProject"
        :popper-append-to-body="false"
        no-data-text="无项目"
        filterable
      >
        <el-option
          v-for="i in list"
          :key="i.projectCode"
          :value="i.projectCode"
          :label="i.projectName"
        ></el-option>
      </el-select>
    </div>

    <v-exit />

    <!-- <el-input
      ref="searchInput"
      size="mini"
      clearable
      v-model="searchParams.projectName"
      :class="[
        'vh-searchInput',
        searchInputWidth == 0 ? '' : 'searchInput--large',
      ]"
      :placeholder="searchInputWidth == 0 ? '' : '请输入内容'"
      :style="{ width: searchInputWidth + 'px' }"
      @blur="blurSearch"
      @keyup.enter.native="onSearch"
    >
      <i
        slot="prefix"
        class="el-input__icon el-icon-search vh-searchInput-icon"
        @click.stop="clickSearch"
      ></i>
    </el-input> -->
  </div>
</template>

<script lang="js">
 import store from '@/store'
import VTitleBg from "./v-title-bg.vue";
import { mapState } from "vuex";
import VExit from "./v-exit.vue";
export default {
  name: 'VHeader',
  components: {
    VTitleBg,
    VExit
  },
  data() {
    return {
      project: "",
      list: [],
      searchInputWidth: 0,
      searchParams: {
        pageNum: 1,         //页码
        pageSize: 120,       //每页条数
        projectType: "",    //项目类型
        projectName: "",    //项目名称
        projectCode: "", 
      }
    }
  },
  computed: {
    ...mapState(["projectCurrentRowData"])
  },
  mounted() {
    this.getprojectList();
  },
  methods: {
    changeProject() {
      const item = this.list.find(i => i.projectCode === this.project);
      store.commit("setProjectCurrentRowData", item)
      this.$emit('changeProject', item)
    },
    clickSearch() {
      this.searchInputWidth = 200;
      this.$refs.searchInput.focus();
    },
    blurSearch() {
      if (!this.searchParams.projectName) {
        this.searchInputWidth = 0;
      }
    },
    // 确认搜索
    // onSearch() {
    //   this.getprojectList();
    //   this.$emit('onSearch', this.searchParams)
    // },
    // 获取该租户下的项目列表
    getprojectList() {
      this.request.projectList(this.searchParams).then(res => {
        this.list = (res.data || []).filter(i => i.status != 10);
        const len = this.list.length;
        len > 0 ? store.commit("setProjectCurrentRowData", this.list[0]) : {};
        this.setSelectValue();
      })
    },
    setSelectValue() {
      const { projectCode } = this.projectCurrentRowData;
      this.project = projectCode;
    }
  }
}
</script>
<style scoped lang="scss">
.v-header {
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  > span {
    ::v-deep .v-title-bg {
      font-family: Do Hyeon;
      font-size: 28px;
      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(232, 249, 255, 0.84) 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      position: relative;
    }
  }
  > .vh-list {
    margin: 0 0 0 28px;

    ::v-deep .el-select {
      .el-input input {
        background-color: transparent;
        border: none;
        color: #FFFFFF;
        font-size: 18px;
      }
      .el-input__suffix {
        right: 15%;
      }
    }
  }

  > .vh-searchInput {
    transition: all 0.2s linear;
    background-color: transparent;
    .vh-searchInput-icon {
      cursor: pointer;
    }

    ::v-deep .el-input__inner {
      padding: 0;
      min-width: 24px;
      height: 24px;
      opacity: 1;
      box-sizing: border-box;
      border: 1px solid rgba(242, 243, 245, 0.15);
      background-color: transparent;
      border-radius: 50%;
      color: #fff;
    }

    ::v-deep .el-input__prefix {
      left: 0;
      line-height: 24px;
    }

    &.searchInput--large ::v-deep .el-input__inner {
      padding-left: 24px;
      border-radius: 4px;
    }
  }
}
</style>
